<template>
  <Croppa
    :initial-image="initialImage ? initialImage : null"
    :prevent-white-space="true"
    :show-remove-button="true"
    remove-button-color="black"
    :show-loading="true"
    placeholder="Click to upload (or drag and drop)"
    :placeholder-font-size="14"
    :width="width"
    :height="height"
    @new-image-drawn="imageAddedFn"
    @move="imageAddedFn"
    @zoom="imageAddedFn"
    v-model="image"
    initial-size="natural"
  ></Croppa>
</template>

<script>
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
export default {
  name: "ImageCropper",
  components: {
    Croppa: Croppa.component,
  },
  props: {
    width: Number,
    height: Number,
    initialImage: [String, Boolean],
  },
  data() {
    return {
      image: {},
    };
  },
  watch: {
    image: {
      immediate: true,
      handler() {
        this.imageAddedFn();
      },
    },
  },
  methods: {
    imageAddedFn() {
      setTimeout(() => {
        if (this.image.generateDataUrl()) {
          this.$emit("getBase64", this.image.generateDataUrl());
        }
      }, 100);
    },
  },
};
</script>
<style scoped>
.croppa-container {
  background-color: var(--v-platinum-base);
  border-radius: 8px;
  /* inherit fix (set to initial to reset) */
  line-height: initial;
}
.croppa-container >>> canvas {
  border-radius: 8px;
  overflow: hidden;
}
</style>