<template>
  <v-container id="rfp-page" fluid tag="section">
    <v-row
      class="d-flex flex-row align-items-center justify-content-center"
      no-gutters
    >
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Countries</div>
            <div class="subtitle-1 font-weight-light">
              A list of All Countries
            </div>
          </template>
          <v-card-text>
            <v-row
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-center justify-content-md-center
              "
              no-gutters
            >
              <v-row
                class="mx-0 mb-0"
                align="center"
                justify="center"
                justify-md="start"
              >
                <v-col cols="12" md="8" lg="5">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search by country name"
                    single-line
                    outlined
                    hide-details
                    @keyup.native="searchFn"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" lg="1">
                  <v-btn
                    color="primary"
                    @click="clearFilterFn"
                    :variant="search ? 'ash-gray' : 'primary'"
                    :disabled="!search"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row
              >
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :loading="isLoading"
                  :items-per-page="15"
                  :options.sync="pagination"
                  :footer-props="footerProps"
                  :server-items-length="totalOutputs"
                  @update:items-per-page="getItemPerPage"
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          color="secondary"
                          v-on="on"
                          @click="routeFn(item)"
                        >
                          <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                        </v-btn>
                      </template>
                      <span class="d-block">{{ getRouteName(item) }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.directContact`]="{ item }">
                    <v-switch class="text-center" v-model="item.enableDirectContact"
                    :disabled="item.claimed == false"
                              @change="toggleDirectContact(item)"></v-switch>
                  </template>
                  <template v-slot:[`item.callForTenders`]="{ item }">
                    <v-switch class="text-center" v-model="item.enableOpenTender"
                    :disabled="item.claimed == false"
                              @change="toggleOpenTender(item)"></v-switch>
                  </template>
                  <template v-slot:[`item.DCCTR`]="{ item }">
                    <span class="text-capitalize">{{
                        item.dc_ctr ? item.dc_ctr : "N/A"
                      }}</span>
                  </template>
                  <template v-slot:[`item.country`]="{ item }">
                    <span class="text-capitalize">{{
                        item.name ? item.name : "N/A"
                      }}</span>
                  </template>
                  <template v-slot:[`item.formattedAddress`]="{ item }">
                    <span class="text-capitalize">{{
                        item.formattedAddress ? item.formattedAddress : "N/A"
                      }}</span>
                  </template>
                  <template v-slot:[`item.isAdvance`]="{ item }">
                    <v-btn icon color="grey lighten-1">
                      <font-awesome-icon
                        :icon="['fas', 'ban']"
                        :color="item.isAdvance ? 'green' : 'grey'"
                      />
                    </v-btn>
                  </template>
                  <template v-slot:[`item.isActive`]="{ item }">
                    <v-dialog
                      v-model="item.isModal"
                      :key="item._id"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon>
                          <font-awesome-icon
                            :icon="[
                              'fas',
                              item.active ? 'check-square' : 'ban',
                            ]"
                            :color="item.active ? 'green' : 'grey'"
                          />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title v-if="!item.active">
                          <h3 class="title text-start text-break">
                            Are you sure you want to set {{ item.name }}'s as
                            active
                          </h3>
                        </v-card-title>
                        <v-card-title v-else>
                          <h3 class="title text-start text-break">
                            Are you sure you want to revoke {{ item.name }}'s
                            active status
                          </h3>
                        </v-card-title>
                        <v-card-text v-if="!item.active">
                          <p class="subtitle-2 text-left">
                            You will be updating the country's active status as
                            active. Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-text v-else>
                          <p class="subtitle-2 text-left">
                            You will be updating the country's active status as
                            inactive. Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="black"
                            text
                            @click="item.isModal = false"
                          >No, Cancel
                          </v-btn
                          >
                          <v-btn
                            v-if="!item.active"
                            color="green"
                            text
                            @click="rankFn(item)"
                          >Yes, Update
                          </v-btn
                          >
                          <v-btn v-else color="red" text @click="rankFn(item)"
                          >Yes, Revoke
                          </v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:[`item.isVerified`]="{ item }">
                    <span
                      class="text-capitalize success--text"
                      v-if="item.verified"
                    >Verified</span
                    >
                    <span class="text-capitalize error--text" v-else
                    >Unverified</span
                    >
                  </template>
                  <template v-slot:[`item.isGoogleId2`]="{ item }">
                    <GoogleIDCountryModal
                      @initFN="initFn"
                      :key="item._id"
                      :item="item"
                      :mapUrl="item.mapUrl"
                    />
                  </template>

                  <template v-slot:[`item.sdg`]="{ item }">
                    <SusutainabilityInit @initFN="initFn" :key="item._id" :item="item"/>
                  </template>

                  <template v-slot:[`item.imageUpload`]="{ item }">
                    <ImageUpload
                      @initFN="initFn"
                      :key="item._id"
                      :item="item"
                    />
                  </template>
                  <!-- <template v-slot:[`item.topicNdSubtopic`]="{ item }">
                    <TopicNdSubtopic
                      @initFN="initFn"
                      :key="item._id"
                      :item="item"
                    />
                  </template>
                  <template v-slot:[`item.sustainabilityCommitment`]="{ item }">
                    <GdsScore @initFN="initFn" :key="item._id" :item="item" />
                  </template> -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Config from "../../../services/config";

import GoogleIDCountryModal from "./Models/GoogleIDCountryModal";
import countryApicalls from "../../../services/country.service";
import DeleteConfirmation from "@/dashboard/Common/DeleteConfirmation";

import ImageUpload from "./Models/ImageUpload";
import SusutainabilityInit from "./Models/SusutainabilityInit.vue";
import directContactApicalls from "../../../services/directContact.service";
// import TopicNdSubtopic from "./Models/TopicNdSubtopic";
// import GdsScore from "./Models/GdsScore.vue";


export default {
  name: "Country",
  components: {
    GoogleIDCountryModal,
    DeleteConfirmation,
    ImageUpload,
    SusutainabilityInit,
    // TopicNdSubtopic,
    // GdsScore,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      items: [],
      totalOutputs: 0,
      footerProps: {"items-per-page-options": [5, 15, 30]},
      pagination: {
        rowsPerPage: 15,
        page: 1,
      },
    };
  },
  computed: {
    headers() {
      // let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;
      // if (adminType == "master") {
      return [
        {
          sortable: false,
          text: "Country",
          value: "country",
          class: "primary--text title",
        },

        {
          sortable: false,
          text: "Address",
          value: "formattedAddress",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Advanced",
          value: "isAdvance",
          class: "primary--text title",
        },
        // {
        //   sortable: true,
        //   text: "Verified",
        //   value: "isVerified",
        //   class: "primary--text title",
        // },
        {
          sortable: false,
          text: "Updated",
          value: "isGoogleId2",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        // {
        //   sortable: false,
        //   text: "WTTC Certificate",
        //   value: "isWTTCCertificate",
        //   class: "primary--text title",
        // },
        // {
        //   sortable: false,
        //   text: "Topic",
        //   value: "topicNdSubtopic",
        //   class: "primary--text title",
        // },
        // {
        //   sortable: false,
        //   text: "GDS",
        //   value: "sustainabilityCommitment",
        //   class: "primary--text title",
        // },
        {
          sortable: false,
          text: "Dir. Contact",
          value: "directContact",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Call for Tenders",
          value: "callForTenders",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "D.C CTR",
          value: "DCCTR",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Sust. Initiatives",
          value: "sdg",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Image Upload",
          value: "imageUpload",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Country Show",
          value: "isActive",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ];
      // } else {
      //   return [
      //     {
      //       sortable: true,
      //       text: "Country",
      //       value: "country",
      //       class: "primary--text title",
      //     },

      //     {
      //       sortable: true,
      //       text: "Address",
      //       value: "formattedAddress",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: true,
      //       text: "Advanced",
      //       value: "isAdvance",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: true,
      //       text: "Verified",
      //       value: "isVerified",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "Updated",
      //       value: "isGoogleId2",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "Delete",
      //       value: "delete",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "WTTC Certificate",
      //       value: "isWTTCCertificate",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "Topics",
      //       value: "topicNdSubtopic",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "GDS",
      //       value: "sustainabilityCommitment",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "Image Upload",
      //       value: "imageUpload",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "Country Show",
      //       value: "isActive",
      //       class: "primary--text title",
      //     },
      //     {
      //       sortable: false,
      //       text: "More",
      //       value: "status",
      //       class: "primary--text title",
      //     },
      //   ];
      // }
    },
  },
  mounted() {
    // this.initFn();
  },
  watch: {
    pagination() {
      this.initFn();
    },
  },
  methods: {
    initFn() {
      this.isLoading = true;
      const {page, rowsPerPage} = this.pagination;

      countryApicalls
        .getAllCountry({
          all: true,
          limit: rowsPerPage,
          page: page,
          name: this.search,
        })
        .then((res) => {
          this.totalOutputs = res.data.data.total;
          this.items = res.data.data.results;
          this.isLoading = false;
        });
    },
    getItemPerPage(val) {
      this.pagination.page = 1;
      this.pagination.rowsPerPage = val;
    },
    rankFn(val) {
      this.isLoading = true;
      let payload = {
        visible: !val.active,
      };
      console.log(payload);
      countryApicalls.setShow(val.code, payload).then((res) => {
        if (res.data) {
          let payload2 = {
            snackBarText: `The country '${val.name}''s staus has been Updated successfully`,
            snackBarColor: "secondary",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.initFn();
        }
        this.isLoading = false;
      });
    },
    // healthBadgeFn(val) {
    //   this.isLoading = true;
    //   let payload = {
    //     certified: !val.isWTTCCertificate,
    //   };
    //   console.log(payload, "test");
    //   countryApicalls.setWTTCCertificate(val.code, payload).then((res) => {
    //     console.log("isWTTCCertificate", res);
    //     if (res.data) {
    //       let payload2 = {
    //         snackBarText: `The city '${val.name}''s WTTC Certificate staus has been Updated successfully`,
    //         snackBarColor: "secondary",
    //       };
    //       this.$store.dispatch("notify/setSnackbarMessage", payload2);
    //       this.initFn();
    //     }
    //     this.isLoading = false;
    //   });
    // },
    routeFn(item) {
      let url = `${Config.userSite}/country${item.url}`;
      var win = window.open(url, "_blank");
      win.focus();
    },
    getRouteName(item) {
      return `${Config.userSite}/country${item.url}`;
    },
    payloadGen(country) {
      return {
        name: country.name,
        type: "country",
        _id: country._id,
        code: country.code,
      };
    },
    deleteFn(val) {
      console.log(val.code);
      this.isLoading = true;
      countryApicalls.setDelete(val.code).then((res) => {
        console.log(res);
        if (res.data.msg) {
          let payload2 = {
            snackBarText: `The country '${val.name}'  has been deleted successfully`,
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.initFn();
        }
        this.isLoading = false;
      });
    },
    searchFn() {
      if (this.search && this.search.length > 0) {
        this.pagination.page = 1;

        this.initFn();
      } else {
        this.clearFilterFn();
      }
    },
    clearFilterFn() {
      this.search = null;

      this.pagination.page = 1;
      this.initFn();
    },
    toggleDirectContact(val) {
      console.log(val)
      directContactApicalls.updateAccess(val._id, val.enableDirectContact)
        .then((res) => {
          console.log(res);
        });
    },
    toggleOpenTender(val) {
      console.log(val)
      directContactApicalls.updateOpenTenderAccess(val._id, val.enableOpenTender)
        .then((res) => {
          console.log(res);
        });
    }
  },
};
</script>
