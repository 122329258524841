import instance from "@/services/instance.js";

const GetAllCountry = (params) => {
  return instance.instanceToken
    .get("/countries", {params})
};

const SetShow = (CountryCode, payload) => {
  return instance.instanceToken
    .post(`/countries/visibility/${CountryCode}`, payload)
};

const SetWTTCCertificate = (CountryCode, payload) => {
  return instance.instanceToken
    .post(`/countries/wttc/${CountryCode}`, payload)
};

const SetVerified = (CountryCode, payload) => {
  return instance.instanceToken
    .post(`/countries/verify/${CountryCode}`, payload)
};

const SetTopic = (CountryCode, payload) => {
  return instance.instanceToken
    .post(`/countries/topics/${CountryCode}`, payload)
};

const SetCityAdminImage = (CountryCode, payload) => {
  return instance.instanceToken
    .post(`/countries/images/${CountryCode}`, payload)
};

const SetDelete = CountryCode => {
  return instance.instanceToken
    .delete(`/countries/${CountryCode}`)

};

const SetCountryVerify = payload => {
  return instance.instanceToken
    .post("/countries/mapdata", payload)
};
const SetSDG = (payload) => {
  return instance.instanceToken
    .put("/countries/updateSdg", payload)
};
const UploadSustainabilityCertificate = (payload) => {
  return instance.instanceFileUpload
    .post(`/supportDocuments/upload_sustainability_certificate`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const DeleteSupportDoc= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete", {
      data: payload
    })
};

const DeleteSustainabilityCertificate= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete_sustainability_certificate", {
      data: payload
    })
};

export default {
  getAllCountry: GetAllCountry,
  setShow: SetShow,
  setWTTCCertificate: SetWTTCCertificate,
  setVerified: SetVerified,
  setTopic: SetTopic,
  setCityAdminImage: SetCityAdminImage,
  setDelete: SetDelete,
  setCountryVerify: SetCountryVerify,
  setSdg: SetSDG,
  uploadSustainabilityCertificate: UploadSustainabilityCertificate,
  deleteSustainabilityCertificate: DeleteSustainabilityCertificate,
  DeleteSupportDoc: DeleteSupportDoc,
};
